<template>
  <div class="summary-render" v-bind:is="processedHtml">

  </div>
</template>

<script>
export default {
  name: 'Summary',
  props: {
    episode: Object,
    moment: Function
  },
  methods: {
    skipTo: function(HH=0, mm, ss) {
      let value = `${HH}:${mm}:${ss}`
      value = HH*60*60 + mm*60 + ss
      this.$emit('playTime', value, this.episode)
    }
  },
  computed: {
    processedHtml: function () {
      let value = ""
      if (this.episode.summary) {value = this.episode.summary.toString()}
      let tempv = '$a57-2@#k€a'
      value = value.replaceAll(/(\\)([0-9]{2}:[0-9]{2}:[0-9]{2})/g, (a,b,c,d)=>{
        return `${c.replaceAll(':',tempv)}`
      })
      value = value.replaceAll(/(\\)([0-9]{2}:[0-9]{2})/g, (a,b,c,d)=>{
        return `${c.replaceAll(':',tempv)}`
      })
      value = value.replaceAll(/[\(]*([0-9]{2}):([0-9]{2}):([0-9]{2})[\)]*/g, (a,b,c,d)=>{
        return `<a class="HH-mm-ss timecode" href="javascript:void(0)" v-on:click="skipTo(${b},${c},${d})"><ion-icon name="time"></ion-icon>${a.replaceAll(':',tempv).replaceAll('(','').replaceAll(')','')}</a>`
      })
      value = value.replaceAll(/[\(]*([0-9]{2}):([0-9]{2})[\)]*/g, (a,b,c)=>{
        return `<a class="mm-ss timecode" href="javascript:void(0)" v-on:click="skipTo(0,${b},${c})"><ion-icon name="time"></ion-icon>${a.replaceAll(':',tempv).replaceAll('(','').replaceAll(')','')}</a>`
      })
      value = value.replaceAll(/<p>--[-]*<\/p>/g, ()=>{
        return `<hr>`
      })
      value = value.replaceAll(tempv, ':')
      for (let i = 1; i <= 6; i++) {
        value = value.replaceAll('<h'+i+'>', '<p>')
        value = value.replaceAll('</h'+i+'>', '</p>')
      }
      return {
        template: `<div>${value.toString()}</div>`,
        methods: { skipTo: this.skipTo },
        props: { episode: this.episode }
      }
    }
  }
}
</script>

<style lang="scss">
a.timecode {
  padding: 0 0.5rem;
}
</style>