<template>
  <div class="waveform">
    <div class="bar" v-for="i in Array(4).keys()" :key="i" :class="'bar-'+i"></div>
  </div>
</template>

<script>
export default {
  name: "Waveform",
  props: {
    isPlaying: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.waveform {
  position: relative;
  display: inline-block;
  height: 21px;
  width: 21px;
}

.bar {
  position: absolute;
  bottom: 0;
  display: inline-block;
  height: 6px;
  width: 3px;
  border-radius: 3px;
  background-color: var(--font-color);
  animation: waveform 1s ease-in-out infinite;
}

.bar-1 {
  animation-delay: .25s;
  animation-duration: .9s;
  left: 6px;
}

.bar-2 {
  animation-delay: .5s;
  animation-duration: 0.85s;
  left: 12px;
}

.bar-3 {
  animation-delay: .75s;
  animation-duration: 1.1s;
  left: 18px;
}

@keyframes waveform {
  0% {
    height: 6px;
  }
  25% {
    height: 100%;
  }
  100% {
    height: 6px;
  }
}
</style>