<template>
  <div class="int-ticker monospace">
    <a href="javascript:void(0)" v-on:click="decrease" class="alt minus">-</a>
    <div class="value">{{i ? i : "N/A"}}</div>
    <a href="javascript:void(0)" v-on:click="increase" class="alt plus">+</a>
  </div>
</template>

<script>
export default {
  name: 'IntTicker',
  data: function() {
    return {
      i: 0
    }
  },
  props: {
    int: Number,
    min: Number,
    max: Number
  },
  mounted: function() {
    this.i = this.int
    if (!this.i) {this.i=0}
  },
  methods: {
    increase: function(e) {
      e.preventDefault()
      if (e.shiftKey) {
        this.i += 10
      } else {
        this.i++
      }
      this.update()
    },
    decrease: function(e) {
      e.preventDefault()
      if (e.shiftKey==true) {
        this.i -= 10
      } else {
        this.i--
      }
      this.update()
    },
    update: function() {
      if (this.i < this.min) {
        this.i = this.min
      }
      if (this.i > this.max) {
        this.i = this.max
      }
      this.$emit('update', this.i)
    }
  }
}
</script>

<style lang="scss" scoped>
.int-ticker {
  display: inline-block;
}

.int-ticker .minus, .int-ticker .plus, .int-ticker .value {
  display: inline-block;
  background-color: var(--font-color-3);
  padding: .5rem;
  border: 2px solid var(--font-color-2);
  min-width: 1rem;
  text-align: center;
}

.int-ticker .value {
  border-left: none;
  border-right: none;
  background-color: var(--font-color-3);
  min-width: 2rem;
}

.int-ticker .minus {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.int-ticker .plus {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.int-ticker a:hover {
  background-color: var(--font-color-2);
}
</style>