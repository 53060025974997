<template>
  <div class="container content space-for-player">
    <div class="breadcrumbs">
      <router-link to="/profile" class="alt"><ion-icon name="arrow-back"></ion-icon> Min Profil</router-link>
    </div>
    <div class="edit password">
      
        <form action="javascript:void(0);" class="input-form">

          <div class="login-input-field">
            <label for="oldPassword">Nuværende Kodeord</label>
            <input :type="showPassword ? 'text' : 'password'" v-model="oldPassword" :placeholder="showPassword ? '' : '●●●●●●●●●●'" required>
          </div>

          <div class="login-input-field">
            <label for="newPassword">Nyt Kodeord</label>
            <input :type="showPassword ? 'text' : 'password'" v-model="newPassword" :placeholder="showPassword ? '' : '●●●●●●●●●●'" required>
          </div>

          <div class="showPassword muted">
            <input type="checkbox" id="showPassword" name="showPassword" v-model="showPassword">
            <label class="cblabel" for="showPassword"> Vis Kodeord</label>
          </div>

          <div class="buttons">
            <a href="javascript:void(0)" class="button green" v-on:click="changePassword">Skift Kodeord</a>
          </div>

        </form>

    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: "Password",
  props: {
    session: String
  },
  data: function() {
    return {
      oldPassword: null,
      newPassword: null,
      showPassword: false
    }
  },
  methods: {
    changePassword: function() {
      if (this.oldPassword && this.newPassword) {
          axios.post(process.env.VUE_APP_API+'/password/'+this.session, {
          password: {
            old: this.oldPassword,
            new: this.newPassword
          }
        })
        .then((res) => {
          this.error = null
          if (res.data.err) {
            this.$emit('message', res.data.err, 'red')
          } else if (res.data.acknowledged) {
            this.$emit('message', 'Kodeord ændret', 'green')
            this.$router.push('/profile')
          }
        })
        .catch((error) => {
          this.$emit('message', error, 'red')
        })
      } else {
        this.$emit('message', 'Mangler input', 'red')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.showPassword {
  margin-top: 1rem;
}

.edit label:not(.cblabel) {
  display: block;
  padding: 2rem 0 1rem 0;
}

.buttons {
  margin-top: 2rem;
  text-align: right;
}

.buttons .button:not(:last-child) {
  margin-right: 1rem;
}
</style>