<template>
  <div class="container content space-for-player">
    <div class="forgot">
      <h2>Glemt Kodeord</h2>
      <div class="notsent" v-if="!sent">
        <p>
          Indtast din email nedenunder.<br>
          Derefter vil du modtage en gendannelses email hvor du kan nulstille dit kodeord.
        </p>
        <form @submit.prevent="submit" class="forgotform">
          <input v-model="email" type="text" />
          <button class="snecd submit alt" :class="[email ? null : 'inactive']" type="submit">Send</button>
        </form>
      </div>
      <div class="sent" v-else>
        En gendannelses email er sendt til {{email}}.
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: "Forgot",
  data: function() {
    return {
      email: null,
      sent: false
    }
  },
  methods: {
    submit: function() {
      if (this.email) {
        axios.post(process.env.VUE_APP_API+'/forgot', {
          email: this.email
        })
        .then(res=>{
          if (res.data) {
            if (res.data.err) {
              this.$emit('message', res.data.err, 'red')
              return
            } else {
              this.sent = true
            }
          } else {
            this.$emit('message', 'Der opstod en fejl', 'red')
          }
        })
        .catch(err=>{
          this.$emit('message', err, 'red')
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.forgot {
  margin-top: 1rem;
}

.snecd {
  display: block;
  margin-top: 1rem;
}
</style>