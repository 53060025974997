<template>
  <div class="session" :class="[s.token == session ? 'this' : '']">
    <div class="connection">
      <div class="useragent" v-if="s.useragent">
        <div class="os">
          <ion-icon v-if="s.useragent.isMobile" name="phone-portrait-outline"></ion-icon>
          <ion-icon v-else-if="s.useragent.isBot" name="bug-outline"></ion-icon>
          <ion-icon v-else name="desktop-outline"></ion-icon>
          {{s.useragent.platform ? s.useragent.platform : s.useragent.os}}
        </div>
        <div class="browser"><ion-icon name="browsers-outline"></ion-icon> {{s.useragent.browser}}</div>
      </div>
      <div class="active" v-if="s.token == session">Denne Session</div>
    </div>
    <div class="time" v-if="s.timestamp || s.lastAccessTime">
      <div class="lastAccess" v-if="s.lastAccessTime">Sidst aktiv for {{moment(s.lastAccessTime).locale('da').from()}}</div>
    </div>

    <div class="actions">
      <a href="javascript:void(0);" v-on:click="toggle" class="alt">
        <ion-icon name="chevron-down-sharp" v-if="!showMore"></ion-icon>
        <ion-icon name="chevron-up-sharp" v-else></ion-icon>
        Detaljer
      </a>
      <a href="javascript:void(0);" v-on:click="deleteSession(s.token)" class="red">Deaktiver Session {{s.token == session ? '(dette vil logge dig ud)' : ''}}</a>
    </div>

    <table class="more monospace" v-if="showMore">
      <tr class="token" v-if="s.token">
        <td class="id">Token</td>
        <td class="value">{{s.token}}</td>
      </tr>
      <tr class="ip" v-if="s.ip">
        <td class="id">IP</td>
        <td class="value">{{ip(s.ip)}}</td>
      </tr>
      <tr class="created" v-if="s.timestamp">
        <td class="id">Oprettet</td>
        <td class="value">{{moment(s.timestamp)}}</td>
      </tr>
      <tr class="access" v-if="s.lastAccessTime">
        <td class="id">Sidst aktiv</td>
        <td class="value">{{moment(s.lastAccessTime)}}</td>
      </tr>
      <tr class="source" v-if="s.useragent.source">
        <td class="id">Source</td>
        <td class="value">{{s.useragent.source}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Session',
  data: function() {
    return {
      showMore: false
    }
  },
  props: {
    session: String,
    s: Object,
    moment: Function
  },
  methods: {
    ip: function(ip) {
      switch (ip) {
        case '::1':
          return 'DEV ENV'
          break;
      
        default:
          let it = ip.replace('::ffff:','')
          if (it.startsWith('192.168')) {
            it = `DEV ENV (${it})`
          }
          return it
          break;
      }
    },
    toggle: function() {
      this.showMore = !this.showMore
    },
    deleteSession: function(x) {
      this.$emit('deleteSession', x)
    }
  }
}
</script>

<style lang="scss" scoped>
.session:not(:last-of-type) {
  border-bottom: 1px solid var(--font-color-3);
}

.more .id {
  font-weight: 700;
  padding-right: 1rem;
  text-align: left;
  white-space: nowrap;
}

.session {
  padding: 1rem 0;
}

.active {
  display: inline-block;
  padding: .25rem .5rem;
  border-radius: 3px;
  background: var(--font-color-3);
}

.more {
  padding-top: 1rem;
}

.useragent, .useragent .browser, .useragent .os {
  display: inline-block;
}

.useragent .browser, .useragent .os {
  padding-right: 1rem;
}

.connection {
  padding-bottom: .5rem;
}

.connection ion-icon {
  font-size: 1.4rem;
  position: relative;
  top: 5px;
}

.actions {
  margin-top: 1rem;
}

.actions a:not(:last-of-type) {
  margin-right: 1rem;
}

.count {
  margin-bottom: 1rem;
}
</style>