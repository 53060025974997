<template>
  <div class="l">
    <div class="played" v-if="progress>finThreshold"><ion-icon name="checkmark-circle"></ion-icon> Afspillet</div>
    <div class="full" v-if="progress>0.01 && progress < finThreshold">
      <div class="inner" :style="{ width: progress >= finThreshold ? 100 + '%' : progress * 100 + '%' }">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Listen',
  props: {
    progress: Number
  },
  data: function() {
    return {
      finThreshold: 0.99
    }
  }
}
</script>

<style lang="scss" scoped>
.l {
  display: flex;
}

.played {
  white-space: nowrap;
  margin-right: .5rem;
  position: relative;
  color: var(--font-color-1);
  margin-bottom: .5rem;
}

.played ion-icon {
  font-size: 1.4rem;
  position: relative;
  top: 6px;
  color: var(--primary-color);
}

.full {
  width: 100%;
  display: block;
  height: 4px;
  background: var(--font-color-3);
  overflow: hidden;
  border-radius: 9999px;
  margin: .5rem 0;
}

.inner {
  height: 100%;
  background: var(--primary-color);
  border-radius: 9999px;
  transition: width .25s;
}
</style>