<template>
  <div class="container content space-for-player">
    <div class="breadcrumbs">
      <router-link to="/profile" class="alt"><ion-icon name="arrow-back"></ion-icon> Min Profil</router-link>
    </div>
    <div class="sessions" v-if="user">
      <h2>Sessioner for {{user.fullname}}</h2>
      <div class="list">
        <div class="count">{{sessions.length}} aktiv{{sessions.length > 1 ? 'e' : null}} session{{sessions.length > 1 ? 'er' : null}} fundet</div>
        <Session v-for="(s, index) in sessions" :key="index" :s="s" :session="session" :moment="moment" @deleteSession="deleteSession" />
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Session from '@/components/Session.vue'

export default {
  name: "Sessions",
  components: {
    Session
  },
  props: {
    user: Object,
    moment: Function,
    session: String
  },
  data: function() {
    return {
      sessions: []
    }
  },
  methods: {
    get: function() {
      axios.get(process.env.VUE_APP_API+'/sessions/'+this.session)
      .then((res) => {
        if (res.data) {
          this.sessions = res.data.sessions
        }
      })
      .catch((error) => {
        console.log(error)
        this.$emit('message', 'Kunne ikke hente sessioner', 'red')
      })
    },
    deleteSession: function(session) {
      this.$emit('deleteSession', session, (err, res)=>{
        if (res) {
          this.get()
        }
      })
    }
  },
  mounted: function() {
    this.get()
  }
}
</script>

<style lang="scss" scoped>
</style>