<template>
  <div class="podcast">
    <router-link class="alt podcast-route" :to="'/podcast/'+podcast.id">
      <div class="cover"><img :src="directusAsset(podcast.cover_1400)" alt=""></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Podcast',
  props: {
    podcast: Object
  },
  methods: {
    directusAsset: function(id) {
      return 'https://directus.mediano.media/assets/' + id
    }
  }
}
</script>

<style lang="scss" scoped>
.podcast {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.cover img {
  border-radius: 3px;
  width: 100%;
}

.cover {
  transition: opacity .15s;
}

.cover:hover {
  opacity: .75;
}

.podcast-route {
  display: block;
}
</style>