<template>
  <div class="profile space-for-player">
    <div class="content" v-if="user">
      <h1 class="myprofile" id="koios">Min Profil <a class="alt edit" href="javascript:void(0)" v-on:click="beginEdit" v-if="!edit"><ion-icon name="create-outline"></ion-icon></a></h1>
      <div class="badges" v-if="user.alphaUser && !edit || user.betaUser && !edit ">
        <Badge :badge="user.alphaUser ? 'alpha' : user.betaUser ? 'beta' : null" />
        <Badge badge="launch" v-if="user.participatedInLaunch"/>
        <Badge :badge="accountAge.toString()" type="accountAge" v-if="accountAge >= 1" />
      </div>
      <table class="details" v-if="!edit">
        <tr class="username">
          <td class="id">Brugernavn</td>
          <td class="value">{{user.username ? user.username : 'N/A'}}</td>
        </tr>
        <tr class="email">
          <td class="id">Email</td>
          <td class="value">{{user.email ? user.email : 'N/A'}}</td>
        </tr>
        <tr class="fullname">
          <td class="id">Navn</td>
          <td class="value">{{user.fullname ? user.fullname : 'N/A'}}</td>
        </tr>
        <tr class="gender">
          <td class="id">Køn</td>
          <td class="value">{{user.gender ? ["Ukendt", "Mand", "Kvinde", "Non-binær"][user.gender.id] : 'N/A'}}</td>
        </tr>
        <tr class="birthday">
          <td class="id">Fødselsdato</td>
          <td class="value">{{user.birthday ? moment(user.birthday).format("YYYY-MM-DD") : 'N/A'}}{{isBirthday ? ' 🍰' : null}}</td>
        </tr>
        <tr class="transport">
          <td class="id">Transportmiddel</td>
          <td class="value">{{user.transport ? user.transport.value : 'N/A'}}</td>
        </tr>
        <tr class="housing">
          <td class="id">Boligform</td>
          <td class="value">{{user.housing ? user.housing.value : 'N/A'}}</td>
        </tr>
      </table>

      <div class="edit" v-if="edit">

        <form action="javascript:void(0);" class="input-form">

          <div class="login-input-field">
            <label for="username">Brugernavn</label>
            <input type="text" v-model="username" placeholder="" required>
          </div>

          <div class="login-input-field">
            <label for="email">Email</label>
            <input type="text" v-model="email" placeholder="" required>
          </div>

          <div class="login-input-field">
            <label for="fullname">Fulde Navn</label>
            <input type="text" v-model="fullname" placeholder="" required>
          </div>

          <div class="login-input-field">
            <label for="gender">Køn</label>
            <select name="gender" v-model="gender" id="gender" required>
              <option value="-1">Vælg...</option>
              <option value="0">Foretrækker ikke at oplyse</option>
              <option value="1">Mand</option>
              <option value="2">Kvinde</option>
              <option value="3">Non-binær</option>
            </select>
          </div>

          <div class="login-input-field">
            <label for="username">Fødselsdato</label>
            <input type="date" v-model="birthday" placeholder="YYYY/MM/DD" min="1900-01-01" :max="new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toISOString().split('T')[0]" required>
          </div>

          <div class="login-input-field">
            <label for="transport">Primære Transportmiddel</label>
            <select name="transport" v-model="transport" id="transport" required>
              <option value="-1">Vælg...</option>
              <option value="0">Offentlig Transport</option>
              <option value="1">Cykel (ikke el)</option>
              <option value="2">Elcykel</option>
              <option value="3">Bil (ikke el)</option>
              <option value="4">Elbil</option>
              <option value="5">Motorcykel/Scooter</option>
              <option value="6">Andet</option>
            </select>
          </div>
          <div class="login-input-field">
            <label for="housing">Boligform</label>
            <select name="housing" v-model="housing" id="housing" required>
              <option value="-1">Vælg...</option>
              <option value="0">Ejerbolig, Hus</option>
              <option value="1">Ejerbolig, Lejlighed</option>
              <option value="2">Andelsbolig</option>
              <option value="3">Lejebolig</option>
              <option value="4">Kollegie</option>
              <option value="5">Andet</option>
            </select>
          </div>

          <div class="buttons">
            <a href="javascript:void(0)" class="button green" v-on:click="save">Gem Ændringer</a>
            <a href="javascript:void(0)" class="button alt" v-on:click="cancel">Annuller</a>
          </div>

        </form>

      </div>

    </div>

    <div class="actions" v-if="user && !edit">
      <router-link to="/stats" class="action alt">Statistik</router-link>
      <router-link to="/profile/password" class="action alt">Skift Kodeord</router-link>
      <router-link to="/sessions" class="action alt">Sessioner</router-link>
      <a href="javascript:void(0)" v-on:click="destroySession()" class="action yellow">Log Ud</a>
      <a href="javascript:void(0)" v-on:click="beginDelete" class="action red">Slet Konto</a>
    </div>

  </div>
</template>

<script>
const axios = require('axios')

import Badge from '@/components/Badge.vue'

export default {
  name: 'Profile',
  data: () => {
    return {
      edit: false,
      username: null,
      email: null,
      fullname: null,
      gender: -1,
      transport: -1,
      housing: -1,
      birthday: null
    }
  },
  props: {
    user: Object,
    session: String,
    moment: Function
  },
  components: {
    Badge
  },
  computed: {
    accountAge: function() {
      return Math.floor((Date.now() - this.user.createdAt) / 31536000000)
    },
    isBirthday: function() {
      return this.moment(this.user.birthday).format("MM-DD") == this.moment(new Date()).format("MM-DD")
    }
  },
  methods: {
    destroySession: function() {
      this.$emit('destroySession')
    },
    alert: function(msg) {
      alert(msg)
    },
    save: function() {
      let userObj = {
        username: this.username,
        email: this.email,
        fullname: this.fullname,
        gender: this.gender,
        birthday: this.birthday,
        transport: this.transport,
        housing: this.housing
      }
      axios.post(process.env.VUE_APP_API+'/edit/'+this.session, {user:  userObj})
      .then(response => {
        if (response.data) {
          if (response.data.err) {
            this.$emit('message', response.data.err, 'red')
          } else {
            if (response.data.acknowledged) {
              this.$emit('message', 'Ændringer gemt', 'green')
              this.$emit('validateSession')
              this.edit=false
            } else {
              this.$emit('message', 'Der opstod en fejl', 'red')
            }
          }
        }
      })
      .catch(error => {
        this.$emit('message', error, 'red')
      });
    },
    beginDelete: function() {
      axios.get(process.env.VUE_APP_API+'/delete/begin/'+this.session)
      .then(response => {
        this.$emit('message', response.data, 'green')
      })
      .catch(error => {
        this.$emit('message', error, 'red')
      });
    },
    beginEdit: function() {
      this.edit = true
    },
    cancel: function() {
      this.edit = false
      this.populate()
    },
    populate: function() {
      if (this.user) {
        if (this.user.username) {this.username = this.user.username}
        if (this.user.email) {this.email = this.user.email}
        if (this.user.fullname) {this.fullname = this.user.fullname}
        if (this.user.gender) {this.gender = this.user.gender.id}
        if (this.user.transport) {this.transport = this.user.transport.id}
        if (this.user.housing) {this.housing = this.user.housing.id}
        if (this.user.birthday) {this.birthday = this.user.birthday}
      }
    }
  },
  mounted: function() {
    this.populate()
  },
  watch: {
    user: function(newVal, oldVal) {
      if (newVal.username) {this.username = newVal.username}
      if (newVal.email) {this.email = newVal.email}
      if (newVal.fullname) {this.fullname = newVal.fullname}
      if (newVal.gender) {this.gender = newVal.gender.id}
      if (newVal.transport) {this.transport = newVal.transport.id}
      if (newVal.housing) {this.housing = newVal.housing.id}
      if (newVal.birthday) {this.birthday = newVal.birthday}
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  margin-top: 2rem;
}

.actions .action {
  margin: auto;
  padding: 0 1rem;
  max-width: 720px;
  display: block;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--font-color-3);
}

@media(min-width: 721px) {
  .actions .action {
    padding: 0;
    padding-top: 1rem;
  }
}

.details .id {
  font-weight: 700;
  padding-right: 1rem;
  text-align: left;
}

.badges {
  margin-bottom: 1rem;
}

.badges *:not(:last-of-type) {
  margin-right: .5rem;
}

.myprofile {
  position: relative;
}

.myprofile .edit {
  position: absolute;
  right: 0;
}

.edit label:not(.cblabel) {
  display: block;
  padding: 2rem 0 1rem 0;
}

.edit select {
  width: 100%;
}

.edit input {
  width: calc(100% - 2rem);
}

.buttons {
  margin-top: 2rem;
  text-align: right;
}

.buttons .button:not(:last-child) {
  margin-right: 1rem;
}
</style>