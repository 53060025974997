<template>
  <div class="feed" :class="[feed ? 'loaded' : '', error ? 'error' : '']">
    <div class="error" v-if="error">
      <div class="notice error">{{error}}</div>
    </div>
    <div class="episodes">
      <Episode v-for="episode in feed" :key="episode.id" @play="$emit('play', episode)" :settings="settings" :player="player" :playerInstance="playerInstance" :episode="episode" :moment="moment" :isPlaying="isPlaying"/>
      <div class="get-more center aligned">
        <a v-if="feed.length>0 && !finished" href="javascript:void(0)" class="button alt" v-on:click="getmore"><ion-icon name="hourglass" v-if="fetchingFeed" class="fetchFeed"></ion-icon>Hent flere episoder</a>
        <p class="thatsall muted center aligned" v-if="feed.length>0 && finished">That's all folks!</p>
      </div>
    </div>
    <div class="dummies" >
      <DummyEpisode v-for="i in [1,2,3]" :key="i" />
    </div>
  </div>
</template>

<script>
import Episode from '@/components/Episode.vue'
import DummyEpisode from '@/components/DummyEpisode.vue'

export default {
  name: "Feed",
  components: {
    Episode,
    DummyEpisode
  },
  props: {
    feed: Array,
    session: String,
    moment: Function,
    player: Object,
    playerInstance: Object,
    error: Error,
    settings: Object,
    isPlaying: Boolean,
    fetchingFeed: Boolean,
    finished: Boolean
  },
  methods: {
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    getmore: function() {
      this.$emit('getMore')
    }
  }
}
</script>

<style lang="scss" scoped>
.feed {
  position: relative;
}

.episodes, .dummies {
  transition: opacity .5s;
}

.dummies {
  width: 100%;
  position: absolute;
  top: 0;
}

.loaded .dummies, .error .dummies {
  opacity: 0;
  pointer-events: none;
}

.episodes {
  opacity: 0;
  transition-delay: .25s;
}

.loaded .episodes {
  opacity: 1;
}

.get-more {
  padding-top: 2rem;
}

.thatsall {
  padding-bottom: 1rem;
}

.fetchFeed {
  padding-right: .5rem;
}
</style>