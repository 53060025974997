<template>
  <div class="content master-feed space-for-player" @touchmove="touchmove" @touchstart="touchstart" @touchend="touchend">
    <div v-if="query" class="breadcrumbs">
      <router-link to="/search" class="alt"><ion-icon name="arrow-back"></ion-icon> Søg</router-link>
    </div>
    <Puller ref="puller"/>
    <h1 id="search" v-if="query">Resultater for '{{query.replace('+', ' ')}}'</h1>
    <Feed :feed="feed" :error="error" @getMore="getFeed" @play="$parent.play" :settings="settings" :player="player" :playerInstance="playerInstance" :moment="moment" :isPlaying="isPlaying" :fetchingFeed="fetchingFeed" :finished="finished"/>
    
    <div class="noFeed center aligned" v-if="feed.length<=0 && finished && !fetchingFeed">
      <ion-icon name="sad"></ion-icon>
      <br />
      Ingen resultater fundet
    </div>

    <div class="noFeedLoading center aligned" v-if="feed.length<=0 && !finished">
      <Ripple />
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import Feed from '@/components/Feed.vue'
import Puller from '@/components/Puller.vue'
import Ripple from '@/components/Ripple.vue'

export default {
  name: 'SearchFeed',
  components: {
    Feed,
    Puller,
    Ripple
  },
  data: function() {
    return {
      error: null,
      query: null,
      feed: [],
      page: 0,
      fetchingFeed: false,
      finished: false
    }
  },
  props: {
    user: Object,
    session: String,
    moment: Function,
    player: Object,
    playerInstance: Object,
    settings: Object,
    isPlaying: Boolean,
  },
  methods: {
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    touchstart: function(e) {
      this.$refs.puller.touchstart(e)
    },
    touchend: function(e) {
      this.$refs.puller.touchend(e)
    },
    touchmove: function(e) {
      this.$refs.puller.touchmove(e)
    },
    getFeed: function() {
      if (this.finished) { return }
      this.fetchingFeed = true;
      axios.get(process.env.VUE_APP_API+'/search/'+this.$route.params.id+'/'+this.session+'/'+this.page)
      .then((res) => {
        if (res.data) {
          if (res.data.data.length == 0 || res.data.data.length < this.maxCount) {
            this.finished = true;
          }
          for (let i = 0; i < res.data.data.length; i++) {
            if(!this.feed.includes(res.data.data[i])) {
              this.feed.push(res.data.data[i])
            }
            if (this.page==0) {
              this.maxCount = res.data.data.length
            }
          }
          this.page++
          this.fetchingFeed = false;
        } else {
          this.error='404'
          this.message('Ingen data i feed', 'red')
          this.fetchingFeed = false;
        }
      })
      .catch((error) => {
        console.log(error)
        this.error = error;
        this.message('Kunne ikke hente feed', 'red')
        this.fetchingFeed = false;
      });
    }
  },
  mounted: function() {
    this.query = this.$route.params.id
    this.getFeed()    
  }
}
</script>

<style lang="scss" scoped>
#podcast {
  text-transform: capitalize;
}

.noFeed {
  font-size: 1.4rem;
  padding-top: 2rem;
  color: var(--font-color-1);
}

.noFeed ion-icon {
  font-size: 2.8rem;
}

.noFeedLoading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>