<template>
  <div class="puller" :class="[touchevent ? 'pulling' : 'nopull', touchevent ? touchevent.success ? 'success' : 'fail' : '', touchevent ? 'state-'+touchevent.state : '']" :style="touchevent ? { 'height': u + 'px' } : {}">
    <div class="i">
      <div class="t">
        {{ touchevent ? touchevent.state == 0 ? 'Træk for at hente feed' : '' : 'Træk for at hente feed' }}
        {{ touchevent ? touchevent.state == 1 ? 'Henter feed' : '' : '' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Puller",
  data: function() {
    return {
      touchevent: null,
      pulltarget: 50
    }
  },
  methods: {
    touchstart: function(e) {
      this.touchevent = {
        start: e.touches[0].clientY,
        upwards: 0,
        scrollPosition: document.getElementById('app').scrollTop,
        success: false,
        state: 0
      }
    },
    touchend: function(e) {
      if (this.touchevent.success) {
        this.touchevent.state = 1
        this.$parent.$emit('refreshFeed')
        this.$parent.$emit('refreshPersonalFeed')
        this.$parent.$emit('getFilter')
        this.touchevent.upwards = this.pulltarget
        setTimeout(()=>{
          this.touchevent = null
        }, 1000)
      } else {
        this.touchevent = null
      }
    },
    touchmove: function(e) {
      if (this.touchevent) {
        var scrollPosition = document.getElementById('app').scrollTop
        if (scrollPosition <= 0) {
          var upwards = e.touches[0].clientY - this.touchevent.start
        } else {
          var upwards = 0
          this.touchevent.start = e.touches[0].clientY
        }
        this.touchevent.success = upwards >= this.pulltarget
        this.touchevent.upwards = upwards
        this.touchevent.scrollPosition = scrollPosition
      }
    }
  },
  computed: {
    u: function() {
      var n = this.touchevent.upwards
      if (n<=this.pulltarget) {
        return n
      } else {
        return this.pulltarget+(n-this.pulltarget)/5
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.puller {
  position: relative;
  left: -1rem;
  top: calc(-1.34em + 1px);
  width: 100vw;
  background: var(--bg-color);
  height: 0;
  overflow: hidden;
  transition: height .1s;

  //vvvv    GPU Acceleration    vvvv

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.puller.state-1 {
  transition: height .33s;
}

.puller.nopull {
  transition: height 1s;
}

.i {
  width: 100%;
  height: 100%;
  background: var(--font-color-3);
  transition: background .5s;
}

.puller.success .i  {
  background: var(--font-color-2);
}

.puller.state-1 .i {
  background: var(--primary-color);
}

.t {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>