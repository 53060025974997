<template>
  <div class="news content space-for-player">
    <h1>Nyheder</h1>
    <div class="articles">
      <Article class="articles-article" v-for="article in news" :key="article.id" :article="article" :moment="moment" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Article from '@/components/Article.vue'

export default {
  name: 'News',
  components: {
    Article
  },
  props: {
    moment: Function
  },
  data: function() {
    return {
      news: []
    }
  },
  mounted: function() {
    axios.get('https://directus.mediano.media/items/news?sort=-date_created')
      .then(response => {
        this.news = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss" scoped>
.articles-article {
  margin-bottom: 1rem;
  display: block;
}
</style>