<template>
  <div class="content master-feed space-for-player">
    <h1 class="center aligned">Søg i Mit Mediano</h1>
    <form @submit.prevent="submit" class="searchform center aligned">
      <input v-model="query" type="text" class="searchbox" />
      <button class="submit alt" v-if="query" type="submit">Søg</button>
      <div class="searchicon">
        <ion-icon name="search"></ion-icon>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Search',
  data: function() {
    return {
      query: null
    }
  },
  methods: {
    submit: function() {
      if (this.query) {
        this.$router.push('/search/'+this.query.replace(' ','+'))
      } else {
        this.$emit('message', 'Ingen tekst i søgefelt', 'yellow')
      }
    }
  },
  mounted: function() {
  }
}
</script>

<style lang="scss" scoped>
.searchbox {
  font-size: 1.4rem;
  width: calc(100% - 4rem);
  padding-left: 3rem;
}

.submit {
  margin-top: 1rem;
}

.searchform {
  position: relative;
}

.searchicon {
  position: absolute;
  top: calc(1rem - 1px);
  left: 1rem;
  font-size: 1.4rem;
}
</style>