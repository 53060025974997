<template>
  <div class="badge" :class="[ badge, type, type ? type=='accountAge' ? type+'-'+badge : type : null ]" v-if="badge">
    {{
      badge=="alpha" ? 'Alpha' :
      badge=="beta" ? 'Beta' :
      badge=="launch" ? 'Launch 🚀' :
      type ? type=='accountAge' ? badge + ' år' :
      badge : badge
    }}
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    badge: String,
    type: String
  }
}
</script>

<style lang="scss" scoped>
.badge {
  display: inline-block;
  background: var(--gray);
  border-radius: 9999px;
  padding: 4px 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.badge.alpha {
  background-color: #53c5ee;
	animation: alpha 10s ease infinite;
  box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 4px #53c5ee;
}

.badge.beta {
  background-color: var(--yellow);
}

.badge.launch {
  background-color: var(--font-color);
  color: var(--bg-color);
}

@keyframes alpha {
	0% {
		background-color: #53c5ee;
    box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 4px #53c5ee;
	}
  25% {
    background-color: #34e4bb;
    box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 6px #34e4bb;
  }
  50% {
    background-color: #ec6599;
    box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 4px #ec6599;
  }
  75% {
    background-color: #b664e6;
    box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 6px #b664e6;
  }
	100% {
		background-color: #53c5ee;
    box-shadow: 0 0 0 2px var(--bg-color), 0 0 0 4px #53c5ee;
	}
}
</style>