<template>
  <div class="content podcasts">
    <Podcast class="i-p" v-for="podcast in podcasts" :podcast="podcast" :key="podcast.id" />
  </div>
</template>

<script>
const axios = require('axios')
import Podcast from '@/components/Podcast.vue'

export default {
  name: "Podcasts",
  components: {
    Podcast
  },
  data: function() {
    return {
      url: 'https://directus.mediano.media/items/podcasts?filter[show_koios]=true&sort=sort',
      podcasts: null,
      error: null
    }
  },
  methods: {
    getPodcasts: function() {

      axios.get(this.url)
      .then((res) => {
        if (res.data) {
          this.podcasts = res.data.data
        } else {
          this.error='404'
        }
      })
      .catch((error) => {
        this.error = error;
      });

    }
  },
  mounted: function() {
    this.getPodcasts()
  }
}
</script>

<style lang="scss" scoped>
.content.podcasts {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.i-p {
  display: inline-block;
  margin: 1rem;
  width: calc(50% - 2rem);
}
</style>