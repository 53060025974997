<template>
  <div class="container content space-for-player">
    <div class="breadcrumbs">
      <router-link to="/settings" class="alt"><ion-icon name="arrow-back"></ion-icon> Indstillinger</router-link>
    </div>
    <h2>Beskeder</h2>
    <div class="nolog muted center aligned" v-if="!messageLog || messageLog.length==0">
      <ion-icon name="newspaper"></ion-icon><br>
      Ingen beskeder at vise
    </div>
    <div class="messageLog" v-else>
      <div class="log">
        <div class="message" v-for="message in messageLog" :key="message.id">
          <div class="t" :class="message.color">
            <div class="t">{{message.message}}</div>
            <div class="id muted">For {{moment(message.id).locale('da').from()}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageLog",
  props: {
    messageLog: Array,
    moment: Function
  }
}
</script>

<style lang="scss" scoped>
.nolog {
  padding: 1rem 0;
}

.nolog ion-icon {
  font-size: 2rem;
}

.log .message {
  padding: .5rem 0;
}

.log .message:not(:last-of-type) {
  border-bottom: 1px solid var(--font-color-3);
}

.log {
  padding-bottom: 1rem;
}
</style>