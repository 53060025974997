<template>
  <div class="episode">
    <div class="top">
      <div class="icon"></div>
      <div class="info">
        <div class="title"></div>
        <div class="podcast"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="summary line-1"></div>
      <div class="summary line-2"></div>
      <div class="summary line-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DummyEpisode'
}
</script>

<style lang="scss" scoped>
.episode {
  width: 100%;
  background-color: var(--bg-color);
  border: 1px solid var(--font-color-3);
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 2rem;
}

.top, .bottom {
  padding: 1rem;
}

.top {
  border-bottom: 1px solid var(--font-color-3);
  display: flex;
}

.summary, .icon, .title, .podcast {
  border-radius: 3px;
  background-color: var(--font-color-3);
  animation: dummy 2s ease-in-out infinite;
}

.icon {
  width: 48px;
  height: 48px;
  display: inline-block;
}

.info {
  width: 80%;
  padding-left: 1rem;
}

.title {
  height: 1.4rem;
  width: 100%;
  animation-delay: .2s;
}

.podcast {
  margin-top: .6rem;
  height: 1rem;
  width: 50%;
  animation-delay: .4s;
}

.summary {
  height: 1rem;
}

.summary:not(:last-child) {
  margin-bottom: .5rem;
}

.summary.line-1 {
  width: 100%;
  animation-delay: .6s;
}

.summary.line-2 {
  width: 100%;
  animation-delay: .8s;
}

.summary.line-3 {
  width: 33%;
  animation-delay: 1s;
}

@keyframes dummy {
  0%   {background-color: var(--font-color-3);}
  25%  {background-color: var(--font-color-2);}
  100% {background-color: var(--font-color-3);}
}
</style>