<template>
  <div class="content about space-for-player" v-if="page">
    <div class="raw" v-html="page.text"></div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'About',
  data: function() {
    return {
      page: null,
      error: null
    }
  },
  props: {
    session: String
  },
  methods: {
    getPage: function() {

      axios.get('https://directus.mediano.media/items/pages/about')
      .then((res) => {
        if (res.data) {
          this.page = res.data.data
        } else {
          this.error='404'
        }
      })
      .catch((error) => {
        this.error = error;
      });

    }
  },
  mounted: function() {
    this.getPage()
  }
}
</script>

<style lang="scss">
.about .keybinds .key {
  font-weight: 700;
  padding-right: 1rem;
  text-align: left;
  min-width: 2rem;
}

.about .keybinds {
  font-family: 'Fira Code', monospace;
}
</style>