<template>
  <div class="content queue space-for-player" v-if="playerInstance">
    <h1>Afspilningskø</h1>
    <div class="red" v-if="playerInstance.queue.length<=0">Ingen episoder i kø</div>
    <div class="episodes" v-else>
      <table>
        <tr class="episode" v-for="(episode, index) in playerInstance.queue" :key="episode.id">
          <td class="index">{{index+1}}</td>
          <td class="epc">
            <router-link class="alt" :to="slug(episode)">{{episode.title}} ({{moment.utc(episode.duration*1000).format('HH:mm:ss')}})</router-link>
          </td>
          <td class="close">
            <a href="javascript:void(0)" v-on:click="pop(index)" class="icon red">
              <ion-icon name="close"></ion-icon>
            </a>
          </td>
        </tr>
      </table>
      <div class="stats" :key="runtime">
        {{playerInstance.queue.length}} {{playerInstance.queue.length==1 ? 'episode' : 'episoder'}} i kø {{runtime > 0 ? '('+moment.utc(runtime*1000).format('HH:mm:ss')+')' : ''}}
      </div>
      <div class="buttons">
        <a href="javascript:void(0)" v-on:click="clear" class="red button"><ion-icon name="trash-bin"></ion-icon> Ryd køen</a>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import { slug } from '@/assets/permalink.js'

export default {
  name: 'Queue',
  props: {
    playerInstance: Object,
    moment: Function
  },
  methods: {
    slug: slug,
    pop: function(index) {
      this.playerInstance.removeFromQueue(index)
    },
    clear: function() {
      this.playerInstance.clearQueue()
    },
  },
  computed: {
    runtime: function() {
      var runtime = 0
      this.playerInstance.queue.forEach(episode => {
        runtime += episode.duration
      })
      return runtime
    }
  }
}
</script>

<style lang="scss" scoped>

.episodes table {
  width: 100%;
  border-spacing: 0;
}

.index, .close {
  min-width: 2rem;
}

.epc {
  width: 100%;
}

.episodes table tr td {
  padding: .5rem 0;
}

.episodes table tr:not(:last-of-type) td {
  border-bottom: 1px solid var(--font-color-3);
}

.buttons, .stats {
  margin-top: 2rem;
}

.close {
  text-align: right;
}

.close .icon {
  font-size: 1.4rem;  
  position: relative;
  top: 1px;
  right: -5px;
}

@media(max-width: 720px) {
  .episodes table {
    width: 100vw;
    position: relative;
    left: -1rem;
  }
  .episodes table tr td:first-of-type {
    padding-left: 1rem;
  }
  .episodes table tr td:last-of-type {
    padding-right: 1rem;
  }
}
</style>