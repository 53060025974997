<template>
  <router-link :to="'/news/'+article.id" class="article incognito" v-if="article">
    <div class="title" v-if="article.title">{{article.title}}</div>
    <div class="date muted" v-if="article.date_created">{{moment(article.date_created).format('YYYY-MM-DD')}} ({{moment(article.date_created).locale('da').from()}})</div>
  </router-link>
</template>

<script>
export default {
  name: 'Article',
  props: {
    article: Object,
    moment: Function
  },
  data: function() {
    return {
      
    }
  },
  mounted: function() {
    
  }
}
</script>

<style lang="scss" scoped>
.article .title {
  font-weight: 700;
  font-size: 1.4rem;
}
</style>