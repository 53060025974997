<template>
  <div class="container content space-for-player">
    <div class="confirm">
      <Ripple class="ripple" v-if="!confirmed" />
      <div class="confirmed" v-if="confirmed"   >
        <h2>Din email er nu bekræftet</h2>
        <p>du kan nu lukke dette vindue og <router-link to="/login">logge ind</router-link>.</p>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import Ripple from '@/components/Ripple.vue'

export default {
  name: "Confirm",
  components: {
    Ripple
  },
  data: () => {
    return {
      confirmed: false
    }
  },
  mounted: function() {
    axios.get(process.env.VUE_APP_API+'/email/'+this.$route.params.id)
    .then((res) => {
      if (res.data) {
        if (res.data.err) {
          console.log(res.data.err)
          this.error = res.data.err;
          this.$emit('message', 'Der opstod en fejl: '+res.data.err, 'red')
        } else {
          if (res.data.emailConfirmed) {
            this.confirmed = true
          } else {
            console.log(res.data.err)
            this.error = res.data.err;
            this.$emit('message', 'Der opstod en fejl', 'red')
          }
        }
      }
    })
    .catch((error) => {
      console.log(error)
      this.error = error;
      this.$emit('message', 'Der opstod en fejl', 'red')
    });
  }
}
</script>

<style lang="scss" scoped>
.confirm {
  position: relative;
  min-height: 25vh;
}

.confirm .ripple {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>