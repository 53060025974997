<template>
  <div class="news-article content space-for-player">
    <div class="breadcrumbs">
      <router-link to="/news" class="alt"><ion-icon name="arrow-back"></ion-icon> Nyheder</router-link>
    </div>
    <h1>{{article.title}}</h1>
    <div class="date muted" v-if="article.date_created">{{moment(article.date_created).format('YYYY-MM-DD')}} ({{moment(article.date_created).locale('da').from()}})</div>
    <div class="buttons">
      <a class="button alt" href="javascript:void(0)" v-on:click="share"><ion-icon name="link"></ion-icon> Del</a>
    </div>

    <div class="line"></div>

    <div class="article-content" v-html="article.content"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'News',
  props: {
    moment: Function
  },
  data: function() {
    return {
      article: {}
    }
  },
  methods: {
    share: function() {
      if (navigator.share) {
        navigator.share({
          title: this.article.title,
          text: 'Læs ' + this.article.title,
          url: window.location.href,
        })
          .catch((error) => console.log('Error sharing', error));
      }
    }
  },
  mounted: function() {
    axios.get('https://directus.mediano.media/items/news/'+this.$route.params.id)
      .then(response => {
        this.article = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss" scoped>
.invalid-session .news-article {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.buttons {
  margin-top: 1rem;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--font-color-3);
  margin: 1rem 0;
}

@media(min-width:721px) {
  .line {
    margin: 2rem 0;
  }
}
</style>