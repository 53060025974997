<template>
  <div class="login-register">
    <div class="blobtainer">
      <div class="blob"></div>
    </div>
    <div class="git-hash">
      <span class="tooltip" :data-tooltip="'#'+gitHash">v{{version}}</span>
    </div>
    <div class="login-logo">
      <img src="@/assets/MEDIANO.svg" alt="">
    </div>
    <div class="input-form-container">
      <div class="input-form-wrapper">
        <form action="javascript:void(0);" class="input-form">
          <h1 class="action-name">{{action=='login' ? 'Log Ind' : 'Registrer Ny Bruger&nbsp;'}} <div class="step-counter" v-if="action=='register'">Trin {{step+1}} af {{maxSteps+1}}</div></h1>
          <div class="alternate-action">
            <p class="muted" v-if="action=='login'">Eller <a href="javascript:void(0)" v-on:click="action='register'">registrer</a> en ny bruger.</p>
            <p class="muted" v-if="action=='register'">Eller <a href="javascript:void(0)" v-on:click="action='login'">log ind</a> som eksisterende bruger.</p>
          </div>
          <div class="error notice login-error" v-if="error">
            {{error}} <a class="close" href="javascript:void(0)" v-on:click='error=null'><ion-icon name="close"></ion-icon></a>
          </div>

          <div class="step step-0" v-if="action=='login' || step==0">
            <div class="login">
              <div class="login-input-field">
                <label for="username">Brugernavn</label>
                <input type="text" v-model="username" placeholder="" required>
              </div>
              <div class="login-input-field" v-if="action=='register'">
                <label for="email">Email</label>
                <input type="email" v-model="email" placeholder="" v-if="action=='register'" required>
              </div>
            </div>
            <div class="login">
              <div class="login-input-field">
                <label for="password">Kodeord</label>
                <input :type="showPassword ? 'text' : 'password'" v-model="password" :placeholder="showPassword ? '' : '●●●●●●●●●●'" required>
                <div class="showPassword muted">
                  <input type="checkbox" id="showPassword" name="showPassword" v-model="showPassword">
                  <label class="cblabel" for="showPassword"> Vis Kodeord</label>
                </div>
              </div>
              <div class="login-input-field" v-if="action=='register'">
                <label for="username">Fødselsdato</label>
                <input type="date" v-model="birthday" placeholder="YYYY/MM/DD" min="1900-01-01" :max="new Date(new Date().setFullYear(new Date().getFullYear() - 13)).toISOString().split('T')[0]" required>
              </div>
            </div>
          </div>

          <div class="step step-1" v-if="action=='register' && step==1">
            <div class="login">
              <div class="login-input-field">
                <label for="fullname">Fulde Navn</label>
                <input type="text" v-model="fullname" placeholder="" required>
              </div>
              <div class="login-input-field">
                <label for="gender">Køn</label>
                <select name="gender" v-model="gender" id="gender" required>
                  <option value="-1">Vælg...</option>
                  <option value="0">Foretrækker ikke at oplyse</option>
                  <option value="1">Mand</option>
                  <option value="2">Kvinde</option>
                  <option value="3">Non-binær</option>
                </select>
              </div>
            </div>
            <div class="login">
              <div class="login-input-field">
                <label for="transport">Primære Transportmiddel</label>
                <select name="transport" v-model="transport" id="transport" required>
                  <option value="-1">Vælg...</option>
                  <option value="0">Offentlig Transport</option>
                  <option value="1">Cykel (ikke el)</option>
                  <option value="2">Elcykel</option>
                  <option value="3">Bil (ikke el)</option>
                  <option value="4">Elbil</option>
                  <option value="5">Motorcykel/Scooter</option>
                  <option value="6">Andet</option>
                </select>
              </div>
              <div class="login-input-field">
                <label for="housing">Boligform</label>
                <select name="housing" v-model="housing" id="housing" required>
                  <option value="-1">Vælg...</option>
                  <option value="0">Ejerbolig, Hus</option>
                  <option value="1">Ejerbolig, Lejlighed</option>
                  <option value="2">Andelsbolig</option>
                  <option value="3">Lejebolig</option>
                  <option value="4">Kollegie</option>
                  <option value="5">Andet</option>
                </select>
              </div>
            </div>
            <div class="login terms-and-conditions">
              <div class="login-input-field">
                <input type="checkbox" id="tandc" name="tandc" v-model="tandc">
                <label class="cblabel" for="tandc"> Jeg accepterer <router-link to="/terms-and-conditions">vilkår &amp; betingelser</router-link>.</label>
              </div>
            </div>
          </div>

          <div class="step step-complete" v-if="action=='complete'">
            <h2>Velkommen {{fullname}}</h2>
            <p>Du skulle meget gerne modtage en email indenfor få minutter hvor du vil blive bedt om at bekræfte din identitet, derefter kan <a href="javascript:void(0)" v-on:click="action='login'">logge ind</a>.</p>
          </div>

          <div class="button-container">
            <button class="button next-button alt" v-on:click="nextStep(-1)" v-if="action=='register' && step==maxSteps"><ion-icon name="arrow-back"></ion-icon> Tilbage</button>
            <button class="button next-button alt" v-on:click="nextStep(+1)" v-if="action=='register' && step<maxSteps" :class="!username || !password || !email || !birthday ? 'inactive' : ''">Videre <ion-icon name="arrow-forward"></ion-icon></button>
            <button class="button"                 v-on:click="login()"      v-if="action=='login'" :class="!username || !password ? 'inactive' : ''">Log Ind</button>
            <button class="button"                 v-on:click="register()"   v-if="action=='register' && step==maxSteps" :class="!username || !password || !email || !birthday || !tandc || !fullname || gender < 0 || transport < 0 || housing < 0 ? 'inactive' : ''">Registrer</button>
            <router-link class="button plain" v-if="action=='login'" to="/forgot">Glemt&nbsp;Kodeord</router-link>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'Login',
  data: function() {
    return {
      action: 'login',
      step: 0,
      maxSteps: 1,
      username: null,
      email: null,
      password: null,
      invite: null,
      birthday: null,
      fullname: null,
      gender: -1,
      transport: -1,
      housing: -1,
      tandc: false,
      error: null,
      gitHash: process.env.VUE_APP_GIT_HASH,
      version: process.env.VUE_APP_VERSION,
      showPassword: false,
      slug: window.location.href.replace(window.location.origin,'')
    }
  },
  props: {
    session: String
  },
  methods: {
    login: function() {
      if (this.username && this.password) {
        axios.post(process.env.VUE_APP_API+'/login', {
          user: {
            username: this.username,
            password: this.password
          }
        })
        .then((res) => {
          this.error = null
          if (res.data.err) {
            this.error = res.data.err
          } else if (res.data.token) {
            this.$parent.setCookie('session', res.data.token, 30)
            this.$emit('createSession', res.data.token, true)
            let redirect = new URLSearchParams(window.location.search).get('r')
            if (redirect) {
              this.$router.push(redirect)
            } else {
              this.$router.push('feed')
            }
          }
        })
        .catch((error) => {
          this.error = error;
        })
      }
    },
    register: function() {
      if (this.username && this.password && this.email && this.birthday && this.fullname && this.gender >= 0 && this.transport >= 0 && this.housing >= 0 && this.tandc) {
        axios.post(process.env.VUE_APP_API+'/user', {
          user: {
            username: this.username,
            password: this.password,
            email: this.email,
            birthday: this.birthday,
            fullname: this.fullname,
            gender: this.gender,
            transport: this.transport,
            housing: this.housing
          }
        })
        .then((res) => {
          this.error = null
          if (res.data.err) {
            this.error = res.data.err
          } else if (res.data.acknowledged) {
            this.action='complete'
          }
        })
        .catch((error) => {
          this.error = error;
        })
      }
    },
    nextStep: function(val=1) {
      if (this.username && this.password && this.email && this.birthday) {
        this.step+=val
      }
    }
  },
  mounted: function() {
    if (this.session) {
      this.$router.push('feed')
    } else {
      if (this.slug.startsWith('/register')) {
        this.action='register'
      }
      if (this.$route.query.invite) {
        this.invite = this.$route.query.invite
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.showPassword {
  margin-top: .5rem;
}

.blobtainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.blob {
  position: absolute;
  width: 150vw;
  left: 50vw;
  height: 150vw;
  top: -33vw;
  border-radius: 100%;
  transform: rotate(22.5deg);
  background-color: #FAACA8;
  background-size: 600% 600%;
  animation: gradient-anim 45s ease infinite;
}

@keyframes gradient-anim {
  0%{background-color: #FAACA8; transform: translate(3vw, -7vw);}
  25%{background-color: #bbfaa8; transfform: translate(-7vw, 3vw);}
  50%{background-color: #a8d5fa; transform: translate(4.5vw, -4vw);}
  75%{background-color: #dca8fa; transfform: translate(-3vw, 7vw);}
  100%{background-color: #FAACA8; transform: translate(3vw, -7vw);}
}

.git-hash {
  color: var(--font-color-2);
  z-index: 9999;
  user-select: none;
  mix-blend-mode: difference;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-family: 'Fira Code', monospace;
}

.step-counter {
  position: relative;
  top: -2px;
  font-weight: 400;
  vertical-align: middle;
  background-color: var(--font-color-3);
  color: var(--font-color-1);
  display: inline-block;
  padding: 8px;
  font-size: 12px;
  border-radius: 3px;
}

.login-logo {
  position: relative;
  top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
  z-index: 1;
}

.light .login-logo {
  filter: invert(1);
}

.login-logo img {
  height: 2.4rem;
}

.button-container {
  margin-top: 2rem;
}

.button-container .button:first-of-type {
  margin-right: 1rem;
}

.login label:not(.cblabel) {
  display: block;
  padding: 2rem 0 1rem 0;
}

.terms-and-conditions {
  padding: 2rem 0 1rem 0;
}

.input-form {
  padding: 2rem;
}

@media (min-width: 440px) {
  .input-form {
    padding: 4rem;
  }
  .login-logo {
    margin-bottom: 2rem;
  }
}

@media (min-width: 721px) {
  .login-logo {
    position: absolute;
    left: 4rem;
    margin-bottom: 0;
    text-align: inherit;
  }
  .input-form-container {
    position: relative;
    height: 100vh;
  }
  .input-form-wrapper {
    height: 100%;
    position: absolute;
  }
  .input-form {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .login {
    display: flex;
    flex-wrap: wrap;
  }
  .login-input-field {
    margin-right: 1rem;
  }
  .step-complete {
    max-width: 550px;
  }
}

@media (max-width: 719px) {
  select {
    width: 100%;
  }
  .blobtainer {
    display: none;
  }
  .login-register {
    margin-bottom: 4rem;
  }
}

@media(max-height: 640px) and (min-width: 721px) {
  .login-logo {
    left: auto;
    right: 4rem;
  }
}
</style>