<template>
  <div class="content privacy space-for-player" v-if="page">
    <div class="raw" v-html="page.text"></div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'Privacy',
  data: function() {
    return {
      page: null,
      error: null
    }
  },
  props: {
    session: String
  },
  methods: {
    getPage: function() {

      axios.get('https://directus.mediano.media/items/pages/privacy')
      .then((res) => {
        if (res.data) {
          this.page = res.data.data
        } else {
          this.error='404'
        }
      })
      .catch((error) => {
        this.error = error;
      });

    }
  },
  mounted: function() {
    this.getPage()
  }
}
</script>

<style lang="scss">
</style>