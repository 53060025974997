<template>
  <div class="content episode space-for-player">
    <div v-if="episode && episode.podcast" class="breadcrumbs">
      <router-link to="/podcast" class="alt">Podcasts</router-link>
      <router-link :to="'/podcast/'+episode.podcast.id" class="alt">{{episode.podcast.name}}</router-link>
    </div>
    <div class="error-container" v-if="error">
      <div class="notice error">{{error}}</div>
    </div>
    <div class="inner" v-if="session && episode">
      <h1 id="koios">{{episode.title}}</h1>
      <div class="info semi-muted">
        <span class="duration">{{moment.utc(episode.duration*1000).format('HH:mm:ss')}}</span>
        <span class="listentime" v-if="listen"> ({{moment.utc(listen.time*1000).format('HH:mm:ss')}})</span>
        <div class="spacer" />
        <span class="pubdate">{{moment(episode.pubdatetime || episode.pubdate).format('YYYY-MM-DD HH:mm:ss')}} ({{moment(episode.pubdatetime || episode.pubdate).locale('da').from()}})</span>
      </div>
      <div class="listen" v-if="listen">
        <Listen :progress="listen.time / episode.duration" />
      </div>
      <div class="buttons">
        <a class="button alt" href="javascript:void(0)" v-on:click="$emit('play', episode)">
          <ion-icon v-if="isPlaying && player.episode.id == episode.id" name="pause"></ion-icon>
          <ion-icon v-else name="play"></ion-icon>
          {{isPlaying && player.episode.id == episode.id ? 'Pause' : 'Afspil'}}
        </a>
        <a class="button alt" href="javascript:void(0)" v-on:click="share"><ion-icon name="link"></ion-icon> Del</a>
        <a class="button" :class="playerInstance.inQueue(episode) ? 'yellow' : 'alt'" href="javascript:void(0)" v-on:click="queue">
          <ion-icon v-if="playerInstance.inQueue(episode)" name="checkmark-circle"></ion-icon>
          <ion-icon v-else name="add-circle-outline"></ion-icon>
          {{playerInstance.inQueue(episode) ? 'Fjern fra' : 'Tilføj til'}} kø
        </a>
      </div>
      <p class="soundcloud warning notice" v-if="audioSource=='sc'">
        Denne episode afspilles via Soundclouds CDN og du vil måske opleve forstyrelser undervejs.
      </p>
      <Summary @playTime="$parent.playTime" :moment="moment" :episode="episode" />
      <hr>
      <div class="tags">
        <div class="leagues" v-if="episode.leagues.length>0">
          <b>Ligaer: </b> <span v-for="(league, index) in episode.leagues" :key="league.id">{{league.leagues_id.name}}{{index!=episode.leagues.length-1 ? ", " : ""}}</span>
        </div>
        <!-- <div class="superliga_clubs" v-if="episode.superliga_clubs.length>0">
          <b>Superliga Klubber: </b> <span v-for="(superliga_club, index) in episode.superliga_clubs" :key="superliga_club.id">{{superliga_club.superliga_clubs_id.name}}{{index!=episode.superliga_clubs.length-1 ? ", " : ""}}</span>
        </div> -->
        <div class="format" v-if="episode.format">
          <b>Format: </b> {{episode.format.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import { permalink, urlify } from '@/assets/permalink.js'

import Listen from '@/components/Listen.vue'
import Summary from '@/components/Summary.vue'

export default {
  name: 'EpisodePage',
  data: function() {
    return {
      episode: null,
      error: null,
      permalink: null,
      listen: null
    }
  },
  components: {
    Listen,
    Summary
  },
  props: {
    session: String,
    moment: Function,
    isPlaying: Boolean,
    player: Object,
    playerInstance: Object
  },
  methods: {
    queue: function() {
      this.$emit('queue', this.episode)
    },
    urlify: (str) => {return urlify(str)},
    getEpisode: function() {

      axios.get(process.env.VUE_APP_API+'/episode/'+this.$route.params.id+'/'+this.session)
      .then((res) => {
        if (res.data) {
          this.episode = res.data.data
          this.permalink = permalink(this.episode)
          let time = new URLSearchParams(window.location.search).get('t')
          if (time) {
            this.$parent.playTime(time, this.episode)
          }
          try {
            this.listen = this.$parent.getListen(this.episode.id)
          } catch (error) {
            
          }
        } else {
          this.error='404'
        }
      })
      .catch((error) => {
        this.error = error;
      });

    },
    share: function() {
      if (navigator.share) {
        navigator.share({
          title: this.episode.title,
          text: 'Lyt til ' + this.episode.title,
          url: this.permalink,
        })
          .catch((error) => console.log('Error sharing', error));
      }
    }
  },
  mounted: function() {
    this.getEpisode()
  },
  computed: {
    audioSource: function() {
      if (this.episode.audio) {
        return "audio"
      } else if (this.episode.cdn_audio) {
        return "cdn"
      } else {
        // return "sc"
	return "cdn"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-container {
  margin: 1rem 0;
}

.buttons {
  margin: 2rem 0 2rem 0;
}

.buttons .button:not(:last-of-type) {
  margin-right: 1rem;
}
</style>
