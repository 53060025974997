<template>
  <div class="container content space-for-player">
    <div class="breadcrumbs">
      <router-link to="/profile" class="alt"><ion-icon name="arrow-back"></ion-icon> Min Profil</router-link>
    </div>
    <div class="stats" v-if="user">
      <h2>Statistik for {{user.fullname}}</h2>
      <table class="details" v-if="stats">
        <tr class="createdAt">
          <td class="id">Bruger Siden</td>
          <td class="value">{{user.createdAt ? moment(user.createdAt).format("YYYY-MM-DD") : 'N/A'}} ({{user.createdAt ? moment(user.createdAt).locale('da').from() : 'Never'}}){{stats.cakeday ? ' 🍰' : null}}</td>
        </tr>
        <tr class="totalTime">
          <td class="id">Total Tid Lyttet</td>
          <td class="value">{{moment.utc(stats.totalTime*1000).format('HH:mm:ss')}}</td>
        </tr>
        <tr class="totalEpisodes">
          <td class="id">Påbegyndte Episoder</td>
          <td class="value">{{stats.totalEpisodes}}</td>
        </tr>
      </table>
      <div class="w">
      <b>Episoder pr. Ugedag</b>
      <div class="weekdays" v-if="stats">
        <div class="day" v-for="(count, day) in stats.weekdays" :key="day" :style="{height: count / stats.weekdayMax * 100 + '%'}" :class="['day-'+day, 'count-'+count]"></div>
      </div>
      <div class="weekkeys" v-if="stats">
        <div class="key" v-for="(count, day) in stats.weekdays" :key="day" :class="['day-'+day, 'count-'+count]">
          <span class="ud">{{ugeDag(day)}}</span>
          <span class="c">{{count}}</span>
        </div>
      </div>
      </div>
      <div class="disclaimer">
        <p class="muted">
          Data indsamling start: 2023-01-23
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stats",
  props: {
    listens: Array,
    user: Object,
    moment: Function
  },
  methods: {
    ugeDag: function(int) {
      switch (int) {
        case 0:
          return 'M'
          break;

        case 1:
          return 'T'
          break;

        case 2:
          return 'O'
          break;

        case 3:
          return 'T'
          break;
      
        case 4:
          return 'F'
          break;

        case 5:
          return 'L'
          break;

        case 6:
          return 'S'
          break;

        default:
          break;
      }
    }
  },
  computed: {
    stats: function() {
      let stats = {
        totalTime: 0,
        totalEpisodes: 0,
        finishedEpisodes: 0,
        weekdays: new Array(7).fill(0),
        weekdayMax: 0,
        cakeday: this.moment(this.user.createdAt).format("MM-DD") == this.moment(new Date()).format("MM-DD")
      }
      if (this.listens) {
        for (let i = 0; i < this.listens.length; i++) {
          stats.totalTime += this.listens[i].time
          stats.totalEpisodes++
          if (this.listens[i].datesListened) {
            for (let j = 0; j < this.listens[i].datesListened.length; j++) {
              let d = (new Date(this.listens[i].datesListened[j]).getDay()+6)%7
              stats.weekdays[d]++
              if (d>stats.weekdayMax) {stats.weekdayMax=d}
            }
          }
        }
      }
      return stats
    }
  }
}
</script>

<style lang="scss" scoped>
.details .id {
  font-weight: 700;
  padding-right: 1rem;
  text-align: left;
}

.disclaimer {
  margin-top: 1rem;
  border-top: 1px solid var(--font-color-3)
}

.w {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid var(--font-color-3)
}

.weekkeys {
  margin-top: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.weekkeys .key {
  text-align: center;
  flex-grow: 1;
  padding: 0 1rem;
  margin: 0 .25rem;
  user-select: none;
  position: relative;
  min-height: 2rem;
}

.weekkeys .key .c, .weekkeys .key .ud {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .25s;
}

.weekkeys .key .c {
  opacity: 0;
}

.w:hover .c {
  opacity: 1;
}

.w:hover .ud {
  opacity: 0;
}

.weekdays {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  align-items: flex-end;
}

.weekdays .day {
  text-align: center;
  flex-grow: 1;
  vertical-align: bottom;
  background: var(--primary-color);
  padding: 0 1rem;
  margin: 0 .25rem;
  min-height: 4px;
  border-radius: 2px;
}

.weekdays .day.day-5, .weekdays .day.day-6 {
  background: var(--font-color-2);
}

.day.count-0, .key.count-0 {
  opacity: .5;
}

.weekdays .day:first-of-type, .weekkeys .key:first-of-type {
  margin-left: 0;
}

.weekdays .day:last-of-type, .weekkeys .key:last-of-type {
  margin-right: 0;
}
</style>