<template>
  <div class="settings space-for-player">
    <div class="content" v-if="so && user">
      <h1 id="koios">Indstillinger</h1>
      <div class="setting">
        <label class="n" for="darkmode">Mørke Tilstand</label>
        <div class="v"><button class="toggle" name="darkmode" :class="so.dark ? 'on' : 'off'" v-on:click="so.dark=!so.dark;u()" :key="t" /></div>
      </div>
      <div class="setting">
        <label class="n" for="small_episode">Små Episoder</label>
        <div class="v"><button class="toggle" name="small_episode" :class="so.small_episode ? 'on' : 'off'" v-on:click="so.small_episode=!so.small_episode;u()" :key="t" /></div>
      </div>
      <h2 id="player">Afspiller</h2>
      <div class="setting">
        <label class="n" for="small_episode">Resterende tid</label>
        <div class="v"><button class="toggle" name="small_episode" :class="so.displayRemainingTime ? 'on' : 'off'" v-on:click="so.displayRemainingTime=!so.displayRemainingTime;u()" :key="t" /></div>
        <div class="muted">I stedet for total tid</div>
      </div>
      <div class="setting">
        <label class="n" for="interval">Skip Interval</label>
        <div class="v"><IntTicker :min="1" :max="999" :int="so.interval ? so.interval : 15" v-on:update="(i)=>{so.interval=i;u()}" /></div>
        <div class="muted desktop-only">Tip: Hold <code class="yellow">shift</code> mens du klikker for at ændre 10 sekunder ad gangen</div>
      </div>
    </div>
    <div class="actions" v-if="user">
      <router-link to="/settings/log" class="action alt">Besked Log</router-link>
      <a href="javascript:void(0)" v-on:click="r" class="action red">Tving genindlæsning <code>(ctrl+r)</code></a>
    </div>
  </div>
</template>

<script>
import IntTicker from '@/components/IntTicker.vue'

export default {
  name: 'Settings',
  components: {
    IntTicker
  },
  data: function() {
    return {
      so: null,
      t: Date.now()
    }
  },
  props: {
    user: Object,
    moment: Function,
    settings: Object
  },
  methods: {
    u: function() {
      this.$emit('setSettings', this.so)
      this.t = Date.now()
    },
    r: function() {
      location.reload(true)
    }
  },
  mounted: function() {
    this.so = this.settings
    if (!this.so) {this.so={}}
    if (this.so.dark==undefined) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.so.dark = true
      } else {
        this.so.dark = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label:not(.cblabel) {
  display: block;
  padding: 2rem 0 0 0;
}

.settings h2 {
  border-top: 1px solid var(--font-color-3);
  padding-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.setting {
  position: relative;
}

.setting .v, .setting .n {
  display: inline-block;
  width: 50%;
}

.setting .v {
  text-align: right;
}

.setting .v input {
  width: calc(100% - 3rem);
}

.actions {
  margin-top: 2rem;
}

.actions .action {
  margin: auto;
  padding: 0 1rem;
  max-width: 720px;
  display: block;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--font-color-3);
}

@media(min-width: 721px) {
  .actions .action {
    padding: 0;
    padding-top: 1rem;
  }
}
</style>