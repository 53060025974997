<template>
  <div class="content master-feed space-for-player" @touchmove="touchmove" @touchstart="touchstart" @touchend="touchend">
    <Puller ref="puller"/>
    <h1 id="mediano">Mediano</h1>
    <Feed :feed="masterFeed" :error="error" @getMore="$parent.getFeed" @play="$parent.play" :settings="settings" :player="player" :playerInstance="playerInstance" :moment="moment" :isPlaying="isPlaying" :fetchingFeed="fetchingFeed" :finished="finished"/>
  </div>
</template>

<script>
const axios = require('axios')
import Feed from '@/components/Feed.vue'
import Puller from '@/components/Puller.vue'

export default {
  name: 'MedianoFeed',
  components: {
    Feed,
    Puller
  },
  data: function() {
    return {
      error: null
    }
  },
  props: {
    user: Object,
    session: String,
    moment: Function,
    player: Object,
    playerInstance: Object,
    settings: Object,
    isPlaying: Boolean,
    masterFeed: Array,
    fetchingFeed: Boolean,
    finished: Boolean
  },
  methods: {
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    touchstart: function(e) {
      this.$refs.puller.touchstart(e)
    },
    touchend: function(e) {
      this.$refs.puller.touchend(e)
    },
    touchmove: function(e) {
      this.$refs.puller.touchmove(e)
    }
  },
  mounted: function() {
    if (this.masterFeed.length<=0) {
      this.$emit('getFeed')
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 0;
}
</style>