<template>
  <div class="container content space-for-player">
    <div class="forgot">
      <h2>Nulstil Kodeord</h2>
      <div class="notsent" v-if="!sent">
        <form @submit.prevent="submit" class="forgotform">
          <label for="password">Nyt kodeord</label>
          <input v-model="password" type="password" />
          <button class="snecd submit alt" :class="[password ? null : 'inactive']" type="submit">Nulstil</button>
        </form>
      </div>
      <div class="sent" v-else>
        <p>Dit kodeord er nu ændret.</p>
        <router-link to="/login" class="button green">Log Ind</router-link>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: "ForgotReset",
  data: function() {
    return {
      password: null,
      sent: false
    }
  },
  methods: {
    submit: function() {
      if (this.password) {
        axios.post(process.env.VUE_APP_API+'/forgot/'+this.$route.params.id, {
          password: this.password
        })
        .then(res=>{
          if (res.data) {
            if (res.data.err) {
              this.$emit('message', res.data.err, 'red')
              return
            } else {
              this.sent = true
            }
          } else {
            this.$emit('message', 'Der opstod en fejl', 'red')
          }
        })
        .catch(err=>{
          this.$emit('message', err, 'red')
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.forgot {
  margin-top: 1rem;
}

.snecd {
  display: block;
  margin-top: 1rem;
}

label {
  display: block;
  padding-bottom: 1rem;
}
</style>