<template>
  <div class="content master-feed space-for-player" @touchmove="touchmove" @touchstart="touchstart" @touchend="touchend">
    <div v-if="podcast" class="breadcrumbs">
      <router-link to="/podcast" class="alt"><ion-icon name="arrow-back"></ion-icon> Podcasts</router-link>
    </div>
    <Puller ref="puller"/>
    <h1 id="podcast">{{podcast}}</h1>
    <div class="description" v-html="description"></div>
    <Feed :feed="feed" :error="error" @getMore="getFeed" @play="$parent.play" :settings="settings" :player="player" :playerInstance="playerInstance" :moment="moment" :isPlaying="isPlaying" :fetchingFeed="fetchingFeed" :finished="finished"/>
  </div>
</template>

<script>
const axios = require('axios')
import Feed from '@/components/Feed.vue'
import Puller from '@/components/Puller.vue'

export default {
  name: 'PodcastFeed',
  components: {
    Feed,
    Puller
  },
  data: function() {
    return {
      error: null,
      podcast: null,
      description: null,
      feed: [],
      page: 0,
      fetchingFeed: false,
      finished: false
    }
  },
  props: {
    user: Object,
    session: String,
    moment: Function,
    player: Object,
    playerInstance: Object,
    settings: Object,
    isPlaying: Boolean,
  },
  methods: {
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    touchstart: function(e) {
      this.$refs.puller.touchstart(e)
    },
    touchend: function(e) {
      this.$refs.puller.touchend(e)
    },
    touchmove: function(e) {
      this.$refs.puller.touchmove(e)
    },
    getFeed: function() {
      if (this.finished) { return }
      this.fetchingFeed = true;
      axios.get(process.env.VUE_APP_API+'/podcast/'+this.$route.params.id+'/'+this.session+'/'+this.page)
      .then((res) => {
        if (res.data) {
          if (res.data.data.length == 0 || res.data.data.length < this.maxCount) {
            this.finished = true;
          }
          for (let i = 0; i < res.data.data.length; i++) {
            if(!this.feed.includes(res.data.data[i])) {
              this.feed.push(res.data.data[i])
            }
            if (this.page==0) {
              this.maxCount = res.data.data.length
            }
          }
          this.page++
          this.fetchingFeed = false;
        } else {
          this.error='404'
          this.message('Ingen data i feed', 'red')
          this.fetchingFeed = false;
        }
      })
      .catch((error) => {
        console.log(error)
        this.error = error;
        this.message('Kunne ikke hente feed', 'red')
        this.fetchingFeed = false;
      });
    }
  },
  mounted: function() {
    this.getFeed()
    
    axios.get('https://directus.mediano.media/items/podcasts/'+this.$route.params.id)
    .then((res) => {
      this.podcast = res.data.data.name
      this.description = res.data.data.description
    })
    .catch((error) => {
      console.log(error)
    });
  }
}
</script>

<style lang="scss" scoped>
#podcast {
  text-transform: capitalize;
}

.description {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-bottom: 1px solid var(--font-color-3);
}
</style>