import { render, staticRenderFns } from "./ForgotReset.vue?vue&type=template&id=369e382f&scoped=true&"
import script from "./ForgotReset.vue?vue&type=script&lang=js&"
export * from "./ForgotReset.vue?vue&type=script&lang=js&"
import style0 from "./ForgotReset.vue?vue&type=style&index=0&id=369e382f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369e382f",
  null
  
)

export default component.exports