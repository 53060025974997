<template>
  <div class="content personal-feed space-for-player"  @touchmove="touchmove" @touchstart="touchstart" @touchend="touchend">
    <Puller ref="puller"/>
    <h1 id="mit-feed" class="my-feed"
      :class="[
        selectedFilters.leagues.length==1 ? 'leagues____'+selectedFilters.leagues[0] : '',
        selectedFilters.superliga_clubs.length==1 ? 'superliga_club____'+selectedFilters.superliga_clubs[0] : '',
        selectedFilters.formats.length==1 ? 'formats____'+selectedFilters.formats[0] : '',
      ]">
      <span class="mit"><span class="m">M</span><span class="i">i</span><span class="t">t </span></span>
      <span class="feed"><span class="f">F</span><span class="e">e</span><span class="ee">e</span><span class="d">d</span></span>
      <a href="javascript:void(0)" v-on:click="toggleFilters" class="filter-toggle" :class="showFilters ? 'button green' : 'alt'" v-if="filters">
        {{showFilters ? 'Gem & Luk' : 'Filtrer'}} <ion-icon :name="showFilters ? 'close' : 'filter'"></ion-icon>
      </a>
    </h1>

    <div class="filters" :class="showFilters ? 'shown' : 'hidden'" v-if="filters">
      <div class="content space-for-player">

        <div class="section" id="leagues">
          <div class="title">
            Ligaer
            <a href="javascript:void(0)" v-if="selectedFilters.leagues.length>0" v-on:click="selectedFilters.leagues=[]" class="alt"><ion-icon name="close-circle"></ion-icon></a>
          </div>
          <div class="options">
            <a href="javascript:void(0)" v-for="league in filters.leagues" :key="league.id" class="alt" :class="selectedFilters.leagues.includes(league.id) ? 'selected' : ''" v-on:click="filter('leagues',league.id)">{{league.name}}</a>
          </div>
        </div>

        <div class="section" id="superliga_clubs" v-if="selectedFilters.leagues.includes('superliga') && showSuperligaClubs">
          <div class="title">
            Superliga Klubber
            <a href="javascript:void(0)" v-if="selectedFilters.superliga_clubs.length>0" v-on:click="selectedFilters.superliga_clubs=[]" class="alt"><ion-icon name="close-circle"></ion-icon></a>
          </div>
          <div class="options">
            <a href="javascript:void(0)" v-for="superliga_club in filters.superliga_clubs" :key="superliga_club.id" class="alt" :class="selectedFilters.superliga_clubs.includes(superliga_club.id) ? 'selected' : ''" v-on:click="filter('superliga_clubs',superliga_club.id)">{{superliga_club.name}}</a>
          </div>
        </div>

        <div class="section" id="formats">
          <div class="title">
            Formater
            <a href="javascript:void(0)" v-if="selectedFilters.formats.length>0" v-on:click="selectedFilters.formats=[]" class="alt"><ion-icon name="close-circle"></ion-icon></a>
          </div>
          <div class="options">
            <a href="javascript:void(0)" v-for="format in filters.formats" :key="format.id" class="alt" :class="selectedFilters.formats.includes(format.id) ? 'selected' : ''" v-on:click="filter('formats',format.id)">{{format.name}}</a>
          </div>
        </div>

        <div class="section" id="special" v-if="filters.special">
          <div class="title">
            Andet
            <a href="javascript:void(0)" v-if="selectedFilters.special ? selectedFilters.special.length>0 : false" v-on:click="selectedFilters.special=[]" class="alt"><ion-icon name="close-circle"></ion-icon></a>
          </div>
          <div class="options">
            <a href="javascript:void(0)" v-for="special in filters.special" :key="special.id" class="alt" :class="[special.id, selectedFilters.special ? selectedFilters.special.includes(special.id) ? 'selected' : '' : '']" v-on:click="filter('special', special.id)">{{special.name}}</a>
          </div>
        </div>

      </div>
    </div>

    <Feed :feed="personalFeed" :error="error" @getMore="$parent.getPersonalFeed" @play="$parent.play" :settings="settings" :player="player" :playerInstance="playerInstance" :moment="moment" :isPlaying="isPlaying" :fetchingFeed="fetchingPersonalFeed" :finished="finishedPersonal"/>

    <div class="noFeed center aligned" v-if="countSelectedFitlers==0 && filters && this.personalFeed.length<=0">
      <ion-icon name="information-circle"></ion-icon>
      <br />
      Ingen filtre valgt
    </div>

    <div class="noFeedLoading center aligned" v-if="personalFeed.length<=0 && countSelectedFitlers>0">
      <Ripple />
    </div>
    
  </div>
</template>

<script>
const axios = require('axios')
import Feed from '@/components/Feed.vue'
import Puller from '@/components/Puller.vue'
import Ripple from '@/components/Ripple.vue'

export default {
  name: 'PersonalFeed',
  components: {
    Feed,
    Puller,
    Ripple
  },
  data: function() {
    return {
      error: null,
      showFilters: false,
      showSuperligaClubs: false,
      tempfilters: {}
    }
  },
  props: {
    user: Object,
    session: String,
    moment: Function,
    player: Object,
    playerInstance: Object,
    settings: Object,
    isPlaying: Boolean,
    masterFeed: Array,
    personalFeed: Array,
    filters: Object,
    selectedFilters: Object,
    fetchingFeed: Boolean,
    fetchingPersonalFeed: Boolean,
    finished: Boolean,
    finishedPersonal: Boolean
  },
  methods: {
    toggleFilters: function() {
      this.showFilters=!this.showFilters

      if (!this.showFilters) { // on hide

        if (JSON.stringify(this.tempfilters) != JSON.stringify(this.selectedFilters)) { // if filters changed

          axios.post(process.env.VUE_APP_API+'/filters/'+this.session, {
            selected: this.selectedFilters
          })
          .then(response => {
            this.$emit('refreshPersonalFeed')
          })
          .catch(error => {
            this.$emit('message', 'Kunne ikke gemme filtre', 'red')
          })

        }

      }

      else {  // on view

        this.tempfilters = JSON.parse(JSON.stringify(this.selectedFilters))

      }
    },
    getFilters: function() {
      this.$emit('getFilters')
    },
    touchstart: function(e) {
      if (!this.showFilters) {
        this.$refs.puller.touchstart(e)
      }
    },
    touchend: function(e) {
      if (!this.showFilters) {
        this.$refs.puller.touchend(e)
      }
    },
    touchmove: function(e) {
      if (!this.showFilters) {
        this.$refs.puller.touchmove(e)
      }
    },
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    filter: function(type, option) {
      this.$emit('filter', type, option)
    }
  },
  mounted: function() {
    if (this.personalFeed.length<=0) {
      this.$emit('getPersonalFeed')
    }
    if (!this.filters) {
      this.$emit('getFilter')
    }
  },
  computed: {
    countSelectedFitlers() {
      var n = 0
      n += this.selectedFilters.leagues.length
      n += this.selectedFilters.superliga_clubs.length
      n += this.selectedFilters.formats.length
      n += this.selectedFilters.special.length
      return n
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

h1 {
  margin-top: 0;
}

.my-feed {
  position: relative;
}

.filter-toggle {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 9999px;
  padding: 4px 12px;
  z-index: 3;
}

.filter-toggle.alt {
  background-color: var(--font-color-3);
}

.filters {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-color-1);
  backdrop-filter: blur(12px);
  overflow: scroll;
  padding-top: 1rem;
}

@supports not (backdrop-filter: blur(12px)) {
  .filters {
    background-color: var(--bg-color);
  }
}

.filters.hidden {
  display: none;
}

.filters .section {
  border-bottom: 1px solid var(--font-color-3);
  padding: 1rem 0;
}

.filters .section:last-of-type {
  border-bottom: none;
}


.filters .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: .5rem;
  position: relative;
}

.filters .title ion-icon {
  font-size: 1rem;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  padding-left: .5rem;
}

.filters .options a {
  background-color: var(--font-color-3);
  border-radius: 9999px;
  padding: 4px 12px;
  font-size: 12px;
  margin-bottom: .33rem;
  margin-right: .33rem;
  display: inline-block;
}

.filters .options a.selected {
  background-color: var(--font-color);
  color: var(--bg-color-1);
}

.noFeed {
  font-size: 1.4rem;
  padding-top: 2rem;
  color: var(--font-color-1);
}

.noFeed ion-icon {
  font-size: 2.8rem;
}

.superliga_club____948be058-435e-45ac-856a-350b4e806c19 .mit {
  color: var(--yellow);
}

.superliga_club____948be058-435e-45ac-856a-350b4e806c19 .feed {
  color: var(--blue);
}

.formats____landsholdet .m, .formats____landsholdet .ee, .formats____landsholdet .d {
  color: var(--red);
}

.filters .options a.nopb.selected {
  background-color: var(--red);
  color: var(--bg-color);
}

.noFeedLoading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fishyfish {
  animation: fishyfish 1.332s infinite ease;
}

@keyframes fishyfish {
  0% {
    transform: rotate(-6.66deg); // NUMBER OF THE FISH
  }
  50% {
    transform: rotate(6.66deg);
  }
  100% {
    transform: rotate(-6.66deg);
  }
}
</style>