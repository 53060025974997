<template>
  <div class="episode" :class="[playerInstance.player.episode ? playerInstance.player.episode.id == episode.id ? 'playing' : '' : '', settings ? settings.small_episode ? 'small-episode' : '' : '', isPlaying ? 'playerPlayingAudio' : '', episode.format ? 'format-' + episode.format.id : 'format-none']">
    <div class="innnersode" v-if="episode">
      <div class="top">
        <div class="icon" v-if="episode.podcast">
          <router-link :to="'/podcast/'+episode.podcast.id" v-if="episode.podcast">
            <img :src="directusAsset(episode.podcast.cover_144)" alt="">
            <div class="on-hover"><ion-icon name="arrow-up"></ion-icon></div>
          </router-link>
          <Waveform class="waveform" v-if="isPlaying && playerInstance.player.episode ? playerInstance.player.episode.id == episode.id : false" :isPlaying="!playerInstance.paused" />
        </div>
        <router-link :to="slug(episode)" class="textPortion">
          <div class="title"><div class="theActualTitle">{{episode.title}}</div></div>
        </router-link>
        <div class="expand"><a class="alt" href="javascript:void(0)" v-on:click="expand()">
          <ion-icon name="chevron-up" v-if="expanded"></ion-icon>
          <ion-icon name="chevron-down" v-if="!expanded"></ion-icon>
        </a></div>
      </div>
      <div class="bottom">
        <p class="soundcloud warning notice" v-if="audioSource=='sc'">
          Denne episode afspilles via Soundclouds CDN og du vil måske opleve forstyrelser undervejs.
        </p>
        <div class="summary" :class="expanded ? 'expanded' : 'not-expanded'">
          <Summary @playTime="playTime" :moment="moment" :episode="episode" />
        </div>
        <div class="listen" v-if="listen">
          <Listen :progress="listen.time / episode.duration" />
        </div>
        <div class="controls">
          <button v-on:click="$emit('play', episode)" class="fake-button play aplay"><ion-icon name="play-circle"></ion-icon></button>
          <button v-on:click="$emit('play', episode)" class="fake-button play pause"><ion-icon name="pause-circle"></ion-icon></button>
          <div class="info small muted">
            <span class="duration">{{moment.utc(episode.duration*1000).format('HH:mm:ss')}}</span>
            <div class="spacer" />
            <span class="pubdate"><span :class="age > 7 ? '' : 'desktop-only'">{{moment(episode.pubdatetime || episode.pubdate).format('YYYY-MM-DD')}}</span> <span :class="age > 7 ? 'desktop-only' : ''"><span class="desktop-only">(</span>{{moment(episode.pubdatetime || episode.pubdate).locale('da').from()}}<span class="desktop-only">)</span></span></span>
          </div>
          <div class="more">
            <a class="alt" href="javascript:void(0)" v-on:click="share"><ion-icon name="link"></ion-icon></a>
            <a :class="playerInstance.inQueue(episode) ? 'green' : 'alt'" href="javascript:void(0)" v-on:click="queue">
              <ion-icon v-if="playerInstance.inQueue(episode)" name="checkmark-circle"></ion-icon>
              <ion-icon v-else name="add-circle-outline"></ion-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Listen from '@/components/Listen.vue'
import Summary from '@/components/Summary.vue'
import Waveform from '@/components/Waveform.vue'

import { permalink, slug } from '@/assets/permalink.js'

export default {
  name: 'Episode',
  components: {
    Listen,
    Summary,
    Waveform
  },
  data: function() {
    return {
      expanded: false,
      theNow: 0,
      age: 0,
      listen: null
    }
  },
  props: {
    episode: Object,
    moment: Function,
    player: Object,
    playerInstance: Object,
    settings: Object,
    isPlaying: Boolean
  },
  methods: {
    permalink: (episode) => {
      return permalink(episode)
    },
    slug: (episode) => {
      return slug(episode)
    },
    directusAsset: function(id) {
      return 'https://directus.mediano.media/assets/' + id
    },
    expand: function() {
      this.expanded = !this.expanded
    },
    urlify: function(str) {
      return str.replace(/[^A-Za-z0-9]/g, "-").toLowerCase()
    },
    playTime: function(time, episode) {
      this.$parent.playTime(time, episode)
    },
    queue: function() {
      this.$parent.$parent.$emit('queue', this.episode)
    },
    share: function() {
      if (navigator.share) {
        navigator.share({
          title: this.episode.title,
          text: 'Lyt til ' + this.episode.title,
          url: this.permalink(this.episode),
        })
          .catch((error) => console.log('Error sharing', error));
      }
    }
  },
  mounted: function() {
    try {
      this.listen = this.$parent.$parent.getListen(this.episode.id)
    } catch (error) {
      this.listen = this.$parent.$parent.$parent.getListen(this.episode.id)
    }
    this.theNow = new Date()
    this.age = (this.theNow - new Date(this.episode.pubdatetime)) / 3600000 / 24
  },
  computed: {
    audioSource: function() {
      if (this.episode.audio) {
        return "audio"
      } else if (this.episode.cdn_audio) {
        return "cdn"
      } else {
        // return "sc"
	return "cdn"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.episode .icon {
  position: relative;
}

.episode .icon img {
  image-rendering: -webkit-optimize-contrast;
}

.episode .icon .on-hover {
  position: absolute;
  top: 0;
  background: var(--player-color-mobile-a);
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  opacity: 0;
  transition: opacity .15s;
}

.episode .icon .on-hover ion-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  color: var(--font-color);
}

.episode .icon:hover .on-hover {
  opacity: 1;
}

.episode .icon img {
  transition: filter .15s;
}

.episode .icon:hover img {
  filter: blur(2px);
}

.episode .icon:hover .waveform {
  opacity: 0 !important;
  pointer-events: none;
}

.episode .icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
  border-radius: 3px;
  height: 48px;
  overflow: hidden;
}

.episode .icon img {
  height: 48px;
}

.episode .textPortion {
  width: 100%;
  display: inline-block;
  max-width: calc(100% - 6rem);
  padding-top: 2px;
  color: var(--font-color);
}

.episode .textPortion:hover {
  color: var(--font-color-1);
}

.episode .textPortion:active {
  color: var(--font-color-2);
}

.episode .theActualTitle {
  display: inline-block;
}

.episode {
  border: 1px solid var(--font-color-3);
  border-radius: 3px;
  margin-bottom: 2rem;
  position: relative;
  //overflow: hidden;
}

.episode, .episode .top {
  transition-duration: .3s;
  transition-property: border, box-shadow;
}

.not-expanded {
  display: -webkit-box;
  line-clamp: 3; 
  -webkit-line-clamp: 3;  
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.expand {
  position: absolute;
  display: inline-block;
  top: 1rem;
  right: 1rem;
  font-size: 1.4rem;
}

.episode .bottom, .episode .top {
  padding: 1rem;
}

.episode .top {
  border-bottom: 1px solid var(--font-color-3);
}

.episode .bottom {
  padding-top: 0;
}

// .episode .waveform {
//   transition: opacity .25s
// }

// .episode:not(.playing) .waveform {
//   opacity: 0
// }

.episode.playing {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 4px var(--primary-color-33);
}

.episode.playing .top {
  border-color: var(--primary-color);
}

.episode .title {
  font-size: 1.4rem;
  font-weight: 700;
}

.episode .controls .info  {
  padding-left: 1.5rem;
  display: inline-block;
  position: relative;
  top: -9px;
}

.episode .controls .info .spacer {
  padding: 0 .25rem;
}

.play {
  font-size: 2rem;
}

.summary {
  margin-block-end: .5em;
  transition: opacity .25s;
}

.summary.not-expanded {
  opacity: .5;
}

@media(max-width: 720px) {
  .episode .title {
    font-size: 1rem;
  }
}



.episode.small-episode {
  border: none;
  border-bottom: 1px solid var(--font-color-3);
  border-radius: 0;
  margin-bottom: 0;
}

@media(min-width: 721px) {
  .episode.small-episode .top, .episode.small-episode .bottom {
    padding-left: 0;
    padding-right: 0;
  }
}

@media(max-width: 720px) {
  .episode.small-episode {
    width: 100vw;
    position: relative;
    left: -1rem;
  }
}

.episode.small-episode .bottom {
  padding-bottom: .5rem;
}

.episode.small-episode .title {
  font-size: 1.2rem;
  }

.episode.small-episode .summary {
  display: none;
}

.episode.small-episode .top {
  border-bottom: none;
  padding-bottom: .5rem;
}

.episode.small-episode .top .expand {
  display: none;
}

.episode.small-episode.playing {
  box-shadow: none;
}

.episode.small-episode.playing .title {
  color: var(--primary-color);
}

.episode .icon {
  position: relative;
}

.episode .icon img {
  image-rendering: -webkit-optimize-contrast;
}

.episode .waveform {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .25s;
}

.episode.playing.playerPlayingAudio .icon .waveform {
  opacity: 1;
}

.episode.playing.playerPlayingAudio .icon {
  box-shadow: INSET 0 0 0 1px var(--primary-color), 0 0 0 4px var(--primary-color-33);
}

.episode.playing .icon {
  transition: box-shadow .25s;
}

.episode.playing.playerPlayingAudio .icon img {
  opacity: .5;
  filter: blur(4px);
}

.episode.playing .icon img {
  transition: opacity .25s, filter .25s;
}

.play.pause {
  display: none;
}

.episode.playing.playerPlayingAudio .play.aplay {
  display: none;
}

.episode.playing.playerPlayingAudio .play.pause {
  display: initial;
}

.more {
  display: inline-block;
  position: absolute;
  right: 1rem;
  bottom: 1.25rem;
  font-size: 1.4rem;
}
.more a:not(:last-child) {
  margin-right: 1rem;
}

@media(min-width: 721px) {
  .episode.small-episode .more {
    right: 0;
  }
}
</style>

<style lang="scss">
.summary p {
  margin-block-end: .5em;
}
</style>
